<template>
  <div class="component-wrapper">
    <b-card>
      <template #header>
        <h4>User Management</h4>
        <b-button variant="primary" class="ml-1" @click.stop="showDetail()">New User Account</b-button>
      </template>
      <template #default>
        <vue-element-loading :active="loader.table" text="Loading User.." :isFullScreen="false"></vue-element-loading>
        <b-table :items="table.items" :fields="table.fields" striped small sort-by="username">
          <template #cell(username)="data">
            <div class="d-flex">
              <span>{{ data.item.username }}</span>
              <span class="ml-auto text-danger">{{ data.item.roleName }}</span>
            </div>
          </template>
          <template #cell(agents)="data">
            <b-badge v-for="(x, i) in data.item.agentUser" :key="i" class="mr-1"
              :variant="x.agentRoleCode === `e` ? `primary` : `warning`">
              {{ x.agentDesc }} [ {{ x.agentRoleDesc }} ]
            </b-badge>
          </template>
          <template #cell(actions)="data">
            <div v-if="user && data.item.userCode != user.userCode">
              <b-button variant="warning" size="sm" @click.stop="showDetail(data.item.userCode)">Edit</b-button>
              <b-button :variant="data.item.isActive ? `danger` : `success`" size="sm" class="ml-1"
                @click.stop="toggle(data.item.userCode)">
                {{ data.item.isActive ? `Disable` : `Enable` }}
              </b-button>
              <b-button variant="info" size="sm" class="ml-1" @click.stop="resetPass(data.item.userCode)">
                Reset Password
              </b-button>
            </div>
          </template>
        </b-table>
      </template>
    </b-card>

    <b-modal v-model="modal.detail" :title="selectedUserCode ? `Edit User` : `Create User`" hide-footer centered lazy
      no-close-on-backdrop no-close-on-esc @hide="selectedUserCode = null">
      <user-detail :userCode="selectedUserCode" @onSaveSuccess="saveSuccess" @onCancel="cancel"></user-detail>
    </b-modal>
  </div>
</template>

<script>
import api from "@api";
import UserDetail from "./Detail.vue";
import { mapState } from "vuex";

export default {
  name: "UserList",
  components: { UserDetail },
  data() {
    return {
      table: {
        items: [],
        fields: [
          { key: "username", label: "Username", sortable: true },
          { key: "email", label: "Email" },
          { key: "agents", label: "Agents" },
          { key: "actions", label: "", class: "text-right" },
        ],
      },
      selectedUserCode: null,
      loader: { table: false },
      modal: { detail: false },
    };
  },
  computed: {
    ...mapState(`auth`, ["user"]),
  },
  mounted() {
    this.loadUser();
  },
  methods: {
    async loadUser() {
      this.loader.table = true;
      try {
        const response = await api.get("/useraccount");
        this.table.items = response;
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        this.loader.table = false;
      }
    },
    showDetail(userCode) {
      this.selectedUserCode = userCode;
      this.modal.detail = true;
    },
    saveSuccess() {
      this.modal.detail = false;
      this.loadUser();
    },
    cancel() {
      this.selectedUserCode = null;
      this.modal.detail = false;
    },
  },
};
</script>
