<template>
  <div>
    <vue-element-loading :active="loader.form"></vue-element-loading>

    <b-form-group label="Username">
      <b-form-input v-model="payload.username" trim />
    </b-form-group>

    <b-form-group label="Email">
      <b-form-input v-model="payload.email" trim />
    </b-form-group>

    <b-form-group label="Roles">
      <v-select v-model="payload.roleCode" :reduce="x => x.roleCode" :options="parameter.roleList" label="roleName"
        placeholder="Choose Roles"></v-select>
    </b-form-group>

    <div v-if="showAgentList">
      Agent Access
      <b-input-group>
        <b-form-select v-model="newAccess.agent" :options="parameter.agentList" text-field="description"
          value-field="code"></b-form-select>
        <b-form-select v-model="newAccess.role" :options="parameter.agentRoleList" text-field="description"
          value-field="code"></b-form-select>
        <template #append>
          <b-button variant="primary" @click.stop="addAccess"> Add Access </b-button>
        </template>
      </b-input-group>

      <b-list-group v-if="payload.agentUser" class="mt-1">
        <b-list-group-item v-for="x in payload.agentUser" :key="x.agentCode"
          class="d-flex justify-content-between align-items-center">
          <span>{{ getAgentName(x.agentCode) }}</span>
          <span class="ml-auto">{{ getAgentRoleName(x.agentRoleCode) }}</span>

          <b-button class="ml-2" size="sm" variant="danger" @click.stop="removeAccess(x)">
            <b-icon icon="trash"></b-icon>
          </b-button>
        </b-list-group-item>
      </b-list-group>
    </div>

    <hr />
    <div class="text-right mt-2">
      <submit-button class="mr-1" @onClick="save"></submit-button>
      <cancel-button @onClick="cancel"></cancel-button>
    </div>
  </div>
</template>

<script>
import api from "@api";

export default {
  name: "UserDetail",
  props: {
    userCode: { type: String, default: null },
  },
  data() {
    return {
      parameter: {
        roleList: [],
        agentList: [],
        agentRoleList: [],
      },

      payload: {
        username: null,
        roleCode: null,
        email: null,
        agentUser: [],
      },

      newAccess: {
        agent: null,
        role: null,
      },

      loader: {
        form: false,
      },
    };
  },

  computed: {
    showAgentList() {
      return this.payload.roleCode == `u`;
    },
  },

  async mounted() {
    this.loadRole();
    this.loadAgentRole();
    this.loadAgent();
    if (this.userCode) await this.loadUser();
  },

  methods: {
    async loadRole() {
      let resp = await api.get(`useraccount/roles`);
      this.parameter.roleList = resp;
    },
    async loadAgentRole() {
      let resp = await api.get(`useraccount/agent/roles`);
      this.parameter.agentRoleList = resp;
    },
    async loadAgent() {
      let resp = await api.get(`agent`);
      this.parameter.agentList = resp;
    },
    async loadUser() {
      this.loader.form = true;
      try {
        let resp = await api.get(`useraccount/${this.userCode}`);
        this.payload = resp;
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        this.loader.form = false;
      }
    },
    async save() {
      this.loader.form = true;
      try {
        console.log(this.payload);
        if (this.userCode) {
          await api.put(`useraccount/${this.userCode}`, this.payload);
        } else await api.post(`useraccount`, this.payload);
        this.clearObject(this.payload);
        this.$emit(`onSaveSuccess`);
        this.showToast(`success`, `Save Success`);
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        this.loader.form = false;
      }
    },

    cancel() {
      this.$emit(`onCancel`);
    },

    addAccess() {
      let newRow = { agentCode: this.newAccess.agent, agentRoleCode: this.newAccess.role };

      if (!this.payload.agentUser.some(x => x.agentCode === newRow.agentCode)) {
        this.payload.agentUser.push(newRow);
      }

      this.newAccess.agent = null;
      this.newAccess.role = null;
    },

    removeAccess(row) {
      this.payload.agentUser = this.payload.agentUser.filter(x => x.agentCode !== row.agentCode);
    },

    getAgentName(code) {
      return this.parameter.agentList.find(x => x.code == code).description;
    },

    getAgentRoleName(code) {
      return this.parameter.agentRoleList.find(x => x.code == code).description;
    },
  },
};
</script>
